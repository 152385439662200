import React from "react";
import _ from "lodash";
import { Tooltip as TooltipWithBounds } from "@visx/tooltip";

const Tooltip = ({ data, open, position }) => {
  data = data || [];
  const [left, top] = position;
  const active = _.orderBy(
    data.filter((entry) => entry.active === undefined || entry.active),
    (entry) => entry?.ratings.length
  ).reverse();
  const firstThree = _.take(active, 3);
  const isMore = data?.length > firstThree?.length;

  return (
    open && (
      <TooltipWithBounds key={Math.random()} top={top + 20} left={left - 210}>
        <>
          {firstThree.map((entry, index) => {
            const style = {
              animationDelay: index * 0.2 + "s",
            };
            const ratings = entry?.ratings?.length;
            const diversity =
              ratings > 2 ? " | " + ~~(entry?.diverse * 100) + "% diverse" : "";
            const controversity =
              ratings > 1
                ? " | " + ~~(entry?.controverse * 100) + "% controverse"
                : "";
            const mlCategory = entry?.categories?.ml || [];
            const tags = entry?.categories?.tags || [];
            const categories = [...new Set(mlCategory), ...new Set(tags)];
            return (
              <div className="entry" key={index} style={style}>
                <div className="tags">{categories.join(" | ")}</div>
                <div className="text">{entry?.text}</div>
                <div className="meta">
                  <small>
                    {entry?.date} | {ratings} rating{ratings > 1 ? "s" : ""}{" "}
                    {diversity} {controversity}
                  </small>
                </div>
              </div>
            );
          })}
          {isMore && (
            <div
              className="more"
              key={"more"}
              style={{
                animationDelay: 3 * 0.2 + "s",
              }}
            >
              ...
            </div>
          )}
        </>
      </TooltipWithBounds>
    )
  );
};

export default Tooltip;
