import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

const Privacy = () => {
  return (
    <div>
      <p>
        Protecting your personal data when you visit our website and use our
        services is important to us.
        <br />
        &nbsp;
        <br />
        “We”, “our” or “us” that is the BMW Foundation Herbert Quandt, contact
        details see here:{" "}
        <a href="https://bmw-foundation.org/de/impressum">
          https://bmw-foundation.org/de/impressum
        </a>
        /.
        <br />
        &nbsp;
        <br />
        The processing of personal data by us via this website, such as the
        name, address, e-mail address, or telephone number of a data subject
        shall always be in line with the General Data Protection Regulation (in
        the following: GDPR), and in accordance with the country-specific data
        protection regulations applicable to the BMW Foundation Herbert Quandt.
        <br />
        &nbsp;
        <br />
        In the following, we would like to inform you about when we collect
        which data, how we use them and on which legal basis.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <br />
          Collection and Processing of Personal Data
        </strong>
        <br />
        &nbsp;
        <br />
        Data protection refers to the protection of personal information. This
        includes information regarding your identity such as your name, e-mail
        address or country of residence. Such personal data are collected and
        stored only if you have given us your consent to do so – for example, by
        participating with an entry in the knowledge platform “Cartographies of
        Change”.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Use, Disclosure and Deletion of Personal Data
        </strong>
        <br />
        &nbsp;
        <br />
        The BMW Foundation Herbert Quandt uses your personal data exclusively
        for the purposes of administering the website and of communicating with
        you. Personal data are processed by BMW Foundation Herbert Quandt
        employees, global contract partners, and by service providers we have
        commissioned, preferably within the EU. Your personal data will not be
        passed on to other third parties or used otherwise without your express
        consent. Exceptions can apply, for example if a legal requirement makes
        it necessary to provide information to authorities for the purposes of
        either public safety or criminal prosecution.
        <br />
        &nbsp;
        <br />
        If data are processed in countries outside of the EU, the BMW Foundation
        Herbert Quandt uses EU standard contracts, including suitable technical
        and organizational measures, to ensure that your personal data are
        processed at the same level as European data protection. If you wish to
        see the specific security precautions for the transfer of your data to
        other countries, please contact us via the communication channels listed
        below.
        <br />
        &nbsp;
        <br />
        In some countries outside the EU, for example Canada and Switzerland,
        the EU has determined that the level of data protection is comparable to
        that in Europe. The comparable level of data protection means that data
        transfer into these countries does not require any special permission or
        agreement.
        <br />
        &nbsp;
        <br />
        Your personal data will be erased if you withdraw your consent (GDPR
        Article 17) via data-privacy@bmw-foundation.org or +49 30 3396 3500
        and/or if the purpose for which we have collected them has ceased to
        exist. We keep your personal data only as long as needed for the purpose
        specified when collecting this respective data or as long as we are
        legally obliged to store this data, for example for reasons of
        commercial or tax law. If the above mentioned conditions are not longer
        met, the respective data will be automatically deleted or stored in a
        way that cannot be used to identify an individual once the last
        specified purpose has been fulfilled.
        <br />
        &nbsp;
        <br />
        Subscribers to the newsletter can unsubscribe from the newsletter any
        time via the unsubscribe link at the end of the newsletter and thus
        revoke their consent to the storage of their e-mail address and name.
        <br />
        &nbsp;
        <br />
        If you want to obtain information about our storage of your personal
        data (GDPR Article 15) or if you wish to correct your data (GDPR Article
        16), please contact us via the communication channels listed below. For
        further data protection related rights see below.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <br />
          Protection of Personal Data
        </strong>
        <br />
        &nbsp;
        <br />
        We deploy various security measures in line with the current state of
        the art to protect and maintain the security, integrity, and
        availability of your data. These measures include:
      </p>
      <p>
        ●&nbsp; strict criteria for authorization to access your data
        (restriction to a limited number of people) and exclusively for the
        specified purpose;
      </p>
      <p>●&nbsp; transfer of acquired data exclusively in encrypted form;</p>
      <p>●&nbsp; storage of confidential data exclusively in encrypted form;</p>
      <p>
        ●&nbsp; firewall safeguarding of IT systems to provide protection
        against unauthorized access, for example by hackers;
      </p>
      <p>
        ●&nbsp; permanent monitoring of access to IT systems to detect and
        prevent the misuse of personal data.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <br />
          Use of Cookies
        </strong>
        <br />
        &nbsp;
        <br />
        A cookie is a small text file which is copied onto your hard disk by a
        website. Cookies do not cause any damage to your computer and do not
        contain any viruses. The cookies from our webpages do not collect any
        personal information about you.
        <br />
        &nbsp;
        <br />
        You can disable the use of cookies any time via your browser settings.
        If you do this, you may not be able to use the full functionality of
        this website.
        <br />
        &nbsp;
        <br />
        We are using the following cookies on our website:
      </p>
      <p>&nbsp;</p>
      <p>Name of cookies: wordpress_[hash] </p>
      <p>Type: session</p>
      <p>
        Usage: When you log in, WordPress uses this cookie to store your
        authentication information. Its use is limited to the administration
        screen area, /wp-admin/
      </p>
      <p>Valid for: end of session</p>
      <p>&nbsp;</p>
      <p>Name of cookies: wordpress_logged_in_[hash]</p>
      <p>Type: session</p>
      <p>
        Usage: After login, wordpress sets the wordpress_logged_in_[hash]
        cookie, which indicates when you’re logged in, and who you are, for most
        interface use.
      </p>
      <p>Valid for: end of session</p>
      <p>&nbsp;</p>
      <p>Name of Cookies: wordpress_test_cookie</p>
      <p>Type: session</p>
      <p>
        Usage: This cookie is set when you navigate to the login page. This
        allows you to check whether the browser is set to allow cookies.
      </p>
      <p>Valid for: end of session</p>
      <p>&nbsp;</p>
      <p>Name of Cookies: wp-settings-[UID]</p>
      <p>Type: Persistent</p>
      <p>
        Usage: WordPress also sets a few wp-settings-[UID] cookies for logged in
        users. The number on the end is your individual user ID from the users
        database table. This is used to customize your view of admin interface,
        and possibly also the main site interface.
      </p>
      <p>Valid for: 1 year</p>
      <p>&nbsp;</p>
      <p>
        Name of Cookies: wp-settings-{"{"}time{"}"}-[UID]
      </p>
      <p>Type: Persistent</p>
      <p>
        Usage: WordPress also sets a few wp-settings-{"{"}time{"}"}-[UID]
        cookies. The number on the end is your individual user ID from the users
        database table. This is used to customize your view of admin interface,
        and possibly also the main site interface.
      </p>
      <p>Valid for: 1 year</p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Data protection provisions Facebook
        </strong>
        <br />
        &nbsp;
        <br />
        On this website, the BMW Foundation Herbert Quandt has integrated
        components of the enterprise Facebook Inc. Facebook is a social network.
        <br />
        &nbsp;
        <br />
        A social network is a place for social meetings on the Internet, an
        online community, which usually allows users to communicate with each
        other and interact in a virtual space. A social network may serve as a
        platform for the exchange of opinions and experiences or enable the
        internet community to provide personal or business-related information.
        Facebook allows social network users to include the creation of private
        profiles, upload photos, and network through friend requests.
        <br />
        &nbsp;
        <br />
        The operating company of Facebook is Facebook, Inc., 1 Hacker Way, Menlo
        Park, CA 94025, United States. If a person lives outside of the United
        States or Canada, the controller is the Facebook Ireland Ltd., 4 Grand
        Canal Square, Grand Canal Harbour, Dublin 2, Ireland.
        <br />
        &nbsp;
        <br />
        With each call-up to one of the individual pages of this Internet
        website, which is operated by the controller and into which a Facebook
        component (Facebook plug-ins) was integrated, the web browser on the
        information technology system of the data subject is automatically
        prompted to download display of the corresponding Facebook component
        from Facebook through the Facebook component. An overview of all the
        Facebook Plug-ins may be accessed under
        https://developers.facebook.com/docs/plugins/. During the course of this
        technical procedure, Facebook is made aware of what specific sub-site of
        our website was visited by the data subject.
      </p>
      <p>&nbsp;</p>
      <p>
        If the data subject is logged in at the same time on Facebook, Facebook
        detects with every call-up to our website by the data subject—and for
        the entire duration of their stay on our Internet site—which specific
        sub-site of our Internet page was visited by the data subject. This
        information is collected through the Facebook component and associated
        with the respective Facebook account of the data subject. If the data
        subject clicks on one of the Facebook buttons integrated into our
        website, e.g. the “Like” button, or if the data subject submits a
        comment, then Facebook matches this information with the personal
        Facebook user account of the data subject and stores the personal data.
      </p>
      <p>&nbsp;</p>
      <p>
        Facebook always receives, through the Facebook component, information
        about a visit to our website by the data subject, whenever the data
        subject is logged in at the same time on Facebook during the time of the
        call-up to our website. This occurs regardless of whether the data
        subject clicks on the Facebook component or not. If such a transmission
        of information to Facebook is not desirable for the data subject, then
        he or she may prevent this by logging off from their Facebook account
        before a call-up to our website is made.
      </p>
      <p>&nbsp;</p>
      <p>
        The data protection guideline published by Facebook, which is available
        at https://facebook.com/about/privacy/, provides information about the
        collection, processing and use of personal data by Facebook. In
        addition, it is explained there what setting options Facebook offers to
        protect the privacy of the data subject. In addition, different
        configuration options are made available to allow the elimination of
        data transmission to Facebook. These applications may be used by the
        data subject to eliminate a data transmission to Facebook.
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>
          <br />
          Knowledge Platform “Cartographies of Change”
        </strong>
        <br />
        &nbsp;
        <br />
        “Cartographies of Change” is an open access knowledge platform, which
        collects statements of concern about the future. The data you enter into
        the typeform is not linked to any of your personal information other
        than your country of residence. Making entries in “Cartographies of
        Change” is optional and voluntary.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Legal Basis for Processing
        </strong>
        <br />
        &nbsp;
        <br />
        Art. 6(1) lit. a GDPR serves as the legal basis for processing
        operations for which the BMW Foundation Herbert Quandt obtain consent
        for a specific processing purpose. If the processing of personal data is
        necessary for the performance of a contract to which the data subject is
        party, as is the case, for example, when processing operations are
        necessary for the supply of goods or to provide any other service, the
        processing is based on Article 6(1) lit. b GDPR.
      </p>
      <p>&nbsp;</p>
      <p>
        The same applies to such processing operations which are necessary for
        carrying out pre-contractual measures, for example in the case of
        inquiries concerning our products or services. Is our company subject to
        a legal obligation by which processing of personal data is required,
        such as for the fulfillment of tax obligations, the processing is based
        on Art. 6(1) lit. c GDPR. In rare cases, the processing of personal data
        may be necessary to protect the vital interests of the data subject or
        of another natural person. This would be the case, for example, if a
        visitor were injured in our company and his name, age, health insurance
        data or other vital information would have to be passed on to a doctor,
        hospital or other third party. Then the processing would be based on
        Art. 6(1) lit. d GDPR. Finally, processing operations could be based on
        Article 6(1) lit. f GDPR. This legal basis is used for processing
        operations which are not covered by any of the abovementioned legal
        grounds, if processing is necessary for the purposes of the legitimate
        interests pursued by our company or by a third party, except where such
        interests are overridden by the interests or fundamental rights and
        freedoms of the data subject which require protection of personal data.
      </p>
      <p>&nbsp;</p>
      <p>
        Such processing operations are particularly permissible because they
        have been specifically mentioned by the European legislator. He
        considered that a legitimate interest could be assumed if the data
        subject is a client of the controller (Recital 47 Sentence 2 GDPR).
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Rights of persons affected
        </strong>
        <br />
        In line with the basic EU data protection regulations, as the person
        affected you have the following rights in particular vis-à-vis the BMW
        Foundation Herbert Quandt:
        <br />
        &nbsp;
        <br />
        Right to information (Article 15 of basic EU data protection
        regulations): You can ask us for information regarding any data of yours
        that we keep at any time. This information concerns, among other things,
        the data categories we process, for what purposes we process them, the
        origin of the data if we did not acquire them directly from you and, if
        applicable, the recipients to whom we have sent your data.
        <br />
        &nbsp;
        <br />
        Right to correction (Article 16 of basic EU data protection
        regulations): You can request that we correct your data. We will
        initiate appropriate measures to keep the data of yours that we
        continuously process correct, complete, and up to date, based the latest
        information available to us.
        <br />
        &nbsp;
        <br />
        Right to deletion (Article 17 of basic EU data protection regulations):
        You can request that we delete your data provided the legal requirements
        have been met. In accordance with Article 17 of basic EU data protection
        regulations, this can be the case if
      </p>
      <p>
        ●&nbsp; The data are no longer required for the purposes they were
        acquired or otherwise processed
      </p>
      <p>
        ●&nbsp; You revoke your consent, which is the basis of the data
        processing, and there is no other legal basis for the processing
      </p>
      <p>
        ●&nbsp; You object to the processing of your data and there are no
        legitimate reasons for the processing or you object to data processing
        for the purposes of direct advertising
      </p>
      <p>
        ●&nbsp; The data have been processed illegally where the processing is
        not necessary
      </p>
      <p>
        ●&nbsp; To ensure adherence to a legal obligation that requires us to
        process your data
      </p>
      <p>●&nbsp; In particular with regard to legal retention periods</p>
      <p>
        ●&nbsp; To assert, exercise or defend against legal claims
        <br />
        &nbsp;
        <br />
        Right to restriction of processing (Article 18 of basic EU data
        protection regulations): You can request that we restrict the processing
        of your data if
      </p>
      <p>
        ●&nbsp; You dispute the correctness of the data – for the period of time
        we need to check the correctness of the data
      </p>
      <p>
        ●&nbsp; The processing is illegal but you do not wish to have your data
        deleted and request a restriction of use instead
      </p>
      <p>
        ●&nbsp; We no longer need your data, but you need them to assert,
        exercise or defend against legal claims
      </p>
      <p>
        ●&nbsp; You have filed an objection to the processing, though it has not
        yet been decided whether our legitimate grounds outweigh yours.
      </p>
      <p>
        Right to data transferability (Article 20 of basic EU data protection
        regulations): At your request, we will transfer your data – where
        technically possible – to another responsible entity. However, this
        right only applies if the data processing is based on your consent or is
        required to fulfill a contract. Instead of receiving a copy of your
        data, you can ask us to send the data directly to another responsible
        entity that you specify.
        <br />
        &nbsp;
        <br />
        Right to objection (Article 21 of basic EU data protection regulations):
        You can object to the processing of your data at any time for reasons
        that arise from your special situation provided the data processing is
        based on your consent or our legitimate interest or that of a third
        party. In this case, we will no longer process your data. The latter
        does not apply if we are able to prove there are compelling, defensible
        reasons for the processing that outweigh your interests or we require
        your data to assert, exercise or defend against legal claims.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Time limits for compliance with the rights of persons affected
        </strong>
        <br />
        &nbsp;
        <br />
        As a general principle, we make every effort to comply with all requests
        within 30 days. This time limit, however, can be extended for reasons
        related to the specific rights of persons affected or complexity of your
        request.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Restriction in the provision of information regarding the rights of
          persons affected
        </strong>
        <br />
        &nbsp;
        <br />
        In certain situations, legal specifications might require us not to
        provide information regarding all of your data. If we have to refuse
        your request for information in such a case, we will inform you of the
        reasons for refusal at the same time.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Complaints to supervisory authorities
        </strong>
        <br />
        &nbsp;
        <br />
        The BMW Foundation Herbert Quandt takes your reservations and rights
        very seriously. However, if you are of the opinion that we have not
        dealt with your complaints or reservations adequately, you have the
        right to submit a complaint to the data privacy protection authorities
        responsible.
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          Please contact us for all matters relating to your personal data at:
        </strong>
      </p>
      <p>
        &nbsp;
        <br />
        <strong>
          <br />
          This privacy policy applies only to content on our servers, not to
          websites linked to or from our website.
        </strong>
      </p>
      <p>[As of: September 2020]</p>
    </div>
  );
};

const PageImprint = () => {
  return (
    <div id="Imprint">
      <div className="inner">
        <h3>Imprint</h3>

        <p>
          AKAW <br />
          Christian Rauch <br />
          Liegnitzer Str. 10 <br />
          10999 Berlin <br /> <br />
          Kontakt: <br />
          Telefon: <a href="tel:004917684887185">+49 176 848 871 85</a> <br />
          E-Mail: <a href="mailto:hello@akaw.vision">hello@akaw.vision</a>
          <br />
        </p>

        <p>
          Umsatzsteuer:
          <br />
          Umst. ID gemäß §27 a Umsatzsteuergesetz:
          <br />
          DE 306643560
          <br />
        </p>

        <p style={{ marginBottom: "2em" }}>
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
          <br />
          Dr. Christian Rauch
          <br />
          Liegnitzer Str. 10
          <br />
          10999 Berlin
          <br />
        </p>

        <h3>Data Privacy Policy</h3>
        <Privacy />
      </div>
    </div>
  );
};

export default PageImprint;
