import React, { useState, useCallback, memo } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";

import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { scaleLinear } from "@visx/scale";
import { withParentSize } from "@visx/responsive";
import { Brush } from "../../vendor/visx-brush/esm";
import { useStore } from "../../store";
import { enableScroll, disableScroll } from "../../helpers";
import Circles from "./Circles";
import AxisCross from "./AxisCross";
import AxisCenter from "./AxisCenter";

const SelectionBrush = memo(({ data, scalePoints, square, onBrushChange }) => {
  const navigate = useNavigate();
  const onBrushStart = useCallback(() => {
    disableScroll();
  });
  const onBrushEnd = useCallback((brushBounds) => {
    enableScroll();
    onBrushChange(null);
    const selected = _.flatten(
      Object.entries(data)
        .filter(([index, points]) => {
          let coords = index.split(",").map(parseFloat);
          coords[1] = 11 - coords[1];
          return brushBounds
            ? coords[0] >= brushBounds.x0 &&
                coords[0] <= brushBounds.x1 &&
                coords[1] >= brushBounds.y0 &&
                coords[1] <= brushBounds.y1
            : false;
        })
        .map(([first, second]) => second)
    );
    if (selected.length > 0) {
      const query = selected.reduce((acc, point) => {
        if (point.active) {
          acc += acc ? "," + point.token : point.token;
        }
        return acc;
      }, "");
      if (query) navigate(`/view/${query}`);
    }
  });
  const onBrushChangeDebounced = useDebouncedCallback(
    (bounds) => onBrushChange(bounds),
    10,
    { leading: true }
  );
  return (
    <Brush
      selectedBoxStyle={{
        fill: "#777",
        stroke: "#000",
        strokeWidth: 0.1,
        fillOpacity: 0.3,
        strokeOpacity: 0.8,
      }}
      xScale={scalePoints}
      yScale={scalePoints}
      width={square}
      height={square}
      resetOnEnd={true}
      onChange={(bounds) => onBrushChangeDebounced.callback(bounds)}
      onBrushEnd={onBrushEnd}
      onBrushStart={onBrushStart}
      brushDirection="both"
    />
  );
});

const Canvas = withParentSize(
  memo(
    ({
      parentWidth: width,
      parentHeight: height,
      onOpen,
      onClose,
      onBrushChange,
    }) => {
      const data = useStore(useCallback((state) => state.data));
      const [brushActive, setBrushActive] = useState(false);

      const square = width > height ? height : width;
      const scalePoints = scaleLinear({
        domain: [1, 10],
        range: [square / 20, square - square / 20],
        round: true,
      });
      const scaleGrid = scaleLinear({
        domain: [0, 1],
        range: [0, square],
        round: true,
      });

      // console.log("Rendered Canvas");

      return (
        <svg
          className="drawing"
          width={square}
          height={square}
          onMouseDown={(event) => {
            if (event.target.tagName !== "circle") setBrushActive(true);
          }}
          onMouseUp={() => {
            setBrushActive(false);
          }}
        >
          <Grid
            xScale={scaleGrid}
            yScale={scaleGrid}
            width={square}
            height={square}
            strokeOpacity={0.6}
          />
          <AxisCross size={square} />
          <AxisCenter size={square} scale={scaleGrid} />

          <SelectionBrush
            data={data}
            scalePoints={scalePoints}
            square={square}
            onBrushChange={onBrushChange}
          />

          <g className={brushActive ? "activeBrush" : "inactiveBrush"}>
            <Circles
              data={data}
              scale={scalePoints}
              onOpen={onOpen}
              onClose={onClose}
            />
          </g>

          <Group id="hovered" />
        </svg>
      );
    }
  )
);

export default Canvas;
