import React from "react";
import { Group } from "@visx/group";
import { Text } from "@visx/text";

const Axis = ({ size, div = 10 }) => {
  return (
    <Group className="axis">
      <Text dx={size / 2 + 5} dy={0} angle={90}>
        desirable
      </Text>
      <Text dx={size / 2 + 5} dy={size - 79} angle={90}>
        undesirable
      </Text>
      <Text dx={size - 58} dy={size / 2 - 5}>
        probable
      </Text>
      <Text dx={0} dy={size / 2 - 5}>
        unprobable
      </Text>
      <line
        className="cross"
        x1={size / div}
        y1={size / div}
        x2={size - size / div}
        y2={size - size / div}
        stroke="red"
        strokeWidth={0.5}
      />
      <line
        className="cross"
        x1={size - size / div}
        y1={size / div}
        x2={size / div}
        y2={size - size / div}
        stroke="red"
        strokeWidth={0.5}
      />
    </Group>
  );
};

export default Axis;
