import React, { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHover } from "react-use-gesture";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { useSpring, animated as a } from "react-spring";
import { useLongPress } from "../../helpers";

const Circle = ({
  points,
  index,
  scale,
  highlighted,
  isTouchDevice,
  onOpen,
  onClose,
}) => {
  const count = points.length;
  const filtered = points.filter(
    (point) => point.active || point.active === undefined
  );
  const size = Math.round(filtered.length * 0.25);
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const { scaler } = useSpring({
    scaler: hovered ? 7 + size * 2 : 4 + size,
  });
  const [first] = points;
  const { x, y } = first;
  const active = filtered.length > 0;
  const ratingsSum = points.reduce(
    (acc, point) => acc + point?.ratings.length || 0,
    0
  );
  const dimm = ratingsSum === count;

  const bind = useHover((state) => {
    if (!active) return;
    if (!state.active) {
      onClose(state.event);
      setHovered(false);
    } else {
      const coords = localPoint(state.event.target.ownerSVGElement, event);
      onOpen({
        tooltipLeft: coords.x,
        tooltipTop: coords.y,
        tooltipData: filtered,
      });
      setHovered(true);
    }
  });

  const onLongPress = (event) => {
    // const coords = localPoint(event.target.ownerSVGElement, event);
    // onOpen({
    //   tooltipLeft: coords.x,
    //   tooltipTop: coords.y,
    //   tooltipData: filtered,
    // });
    // console.log(event.target);
  };

  const onClick = () => {
    const query = points.reduce((acc, point) => {
      if (point.active) {
        acc += acc ? "," + point.token : point.token;
      }
      return acc;
    }, "");
    if (query) navigate(`/view/${query}`);
  };

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 700,
  };
  const longPressEvent = useLongPress(onLongPress, onClick, defaultOptions);

  const highlightedOpacity = highlighted ? (active ? 0.2 : 0) : null;
  const highlightedStyle = { opacity: highlightedOpacity };
  const activeRadius = isTouchDevice ? 17 : 8;
  const children = (
    <Group left={scale(x)} top={scale(11 - y)} {...longPressEvent} {...bind()}>
      <circle fill="transparent" r={activeRadius} />
      <a.circle
        style={highlightedStyle}
        className={active ? "active" : "inactive"}
        key={`radar-point-${index}`}
        r={scaler}
        strokeWidth="1"
        stroke={dimm ? "#9a9a9a" : "#000"}
        fill={hovered ? "black" : "white"}
      />
    </Group>
  );

  return children;
};

export default memo(Circle);
