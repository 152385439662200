import React, {
  useMemo,
  useEffect,
  useLayoutEffect,
  useRef,
  Fragment,
} from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../store";

import Canvas from "./Canvas";
import "./style.scss";

const View = ({ tokens }) => {
  const data = useStore((state) => state.data);
  const referrer = useStore((state) => state.referrer);
  const navigate = useNavigate();

  const entries = useMemo(() => {
    const flattened = _.flatten(Object.values(data));
    const entries = tokens
      .map((token) => _.find(flattened, (entry) => entry.token === token))
      .filter((entry) => entry !== undefined);
    const ordered = _.orderBy(
      entries,
      (entry) => entry?.ratings.length
    ).reverse();
    return ordered;
  }, [tokens]);

  useEffect(() => {
    if (entries.length === 0) navigate(`/`);
  }, [entries]);

  useLayoutEffect(() => {
    let viewportmeta = document.querySelector('meta[name="viewport"]');
    viewportmeta.setAttribute(
      "content",
      "initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, width=device-width, user-scalable=no"
    );
  });

  return (
    <div id="View">
      <div className="inner">
        <nav>
          <a
            href="#back"
            onClick={(event) => {
              event.preventDefault();
              if (referrer) navigate(-1);
              else navigate(`/`);
            }}
          >
            ← go back
          </a>
        </nav>
        <div className="entries ">
          {entries.map((entry) => {
            const ratings = entry?.ratings?.length;
            const diversity =
              ratings > 2 ? " | " + ~~(entry?.diverse * 100) + "% diverse" : "";
            const controversity =
              ratings > 1
                ? " | " + ~~(entry?.controverse * 100) + "% controverse"
                : "";
            const mlCategory = entry?.categories?.ml || [];
            const tags = entry?.categories?.tags || [];
            const categories = [...new Set(mlCategory), ...new Set(tags)];
            return (
              <Fragment key={entry.token}>
                <div className="entry">
                  <div className="colLeft">
                    <Canvas square={120} points={entry?.ratings || []} />
                  </div>
                  <div className="colRight">
                    <div className="tags">{categories.join(" | ")}</div>
                    <div className="text">{entry?.text}</div>
                    <div className="meta">
                      <small>
                        {entry?.date} | {ratings} rating{ratings > 1 ? "s" : ""}{" "}
                        {diversity} {controversity}
                      </small>
                    </div>
                  </div>
                </div>
                <hr />
              </Fragment>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default View;
