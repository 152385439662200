import React from "react";
import { useNavigate } from "react-router-dom";
import SearchField from "./SearchField";
import { useStore } from "../../store";

import "./style.scss";

const DropDown = ({ index, label, items, state, onUpdate }) => {
  const value = state[index] || "";
  const onChange = (event) => {
    const value = event.currentTarget.value;
    if (onUpdate) onUpdate({ filter: index, value: value || null });
  };
  const style = {
    opacity: value ? 1 : 0.5,
  };
  return (
    <select
      className={`${label}-dropdown`}
      style={style}
      value={value}
      onChange={onChange}
    >
      <option value="" className="label">
        {value ? "" : label}
      </option>
      {items.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </select>
  );
};

const Filter = () => {
  const navigate = useNavigate();
  const getFilterGroups = useStore((state) => state.getFilterGroups);
  const filterGroups = getFilterGroups();
  const filters = useStore((state) => state.filters);
  const filtersActive =
    filters.search ||
    filters.country ||
    filters.category ||
    filters.tag1 ||
    filters.tag2 ||
    filters.tag3;

  const setFilter = ({ filter, value }) => {
    const current = {
      ...filters,
      [filter]: value,
    };
    const urlQuery = Object.entries(current).reduce((acc, [key, value]) => {
      if (value) {
        const queryString = `${key}=${value}`;
        acc += acc ? "&" + queryString : "?" + queryString;
      }
      return acc;
    }, "");
    navigate(`/explore${urlQuery}`);
  };

  return (
    <div id="Filter">
      <div className="inner">
        {/* <label className="filter">Filters:</label> */}

        <SearchField
          placeholder="Search..."
          searchText={filters.search || ""}
          classNames="test-class"
          onSearchClick={(value) =>
            setFilter({ filter: "search", value: value || null })
          }
          onEnter={(value) =>
            setFilter({ filter: "search", value: value || null })
          }
        />

        <div className="filter-group">
          {/* {filterGroups.countries.length > 0 && (
            <DropDown
              index="country"
              label="country"
              items={filterGroups.countries}
              state={filters}
              onUpdate={setFilter}
            />
          )} */}

          {filterGroups.categories.length > 0 && (
            <DropDown
              index="category"
              label="Choose Category"
              items={filterGroups.categories}
              state={filters}
              onUpdate={setFilter}
            />
          )}

          {filterGroups.tags.length > 0 && (
            <DropDown
              index="tag1"
              label="Choose Tag"
              items={filterGroups.tags}
              state={filters}
              onUpdate={setFilter}
            />
          )}

          {filterGroups.tags.length > 0 && (
            <DropDown
              index="tag2"
              label="Choose Tag"
              items={filterGroups.tags}
              state={filters}
              onUpdate={setFilter}
            />
          )}

          {filterGroups.tags.length > 0 && (
            <DropDown
              index="tag3"
              label="Choose Tag"
              items={filterGroups.tags}
              state={filters}
              onUpdate={setFilter}
            />
          )}

          <a
            href="#clear"
            className={filtersActive ? "clear active" : "clear inactive"}
            onClick={(event) => {
              event.preventDefault();
              navigate(`/explore`);
            }}
          >
            clear
          </a>
        </div>
      </div>
    </div>
  );
};

export default Filter;
