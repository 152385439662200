import React from "react";
import { useParams } from "react-router-dom";

const PageParticipate = () => {
  const { token, text } = useParams();

  const query =
    "question=" +
    encodeURIComponent(text) +
    "&token=" +
    encodeURIComponent(token);
  return (
    <>
      <iframe
        id="Typeform-rating"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src={`https://cr368540.typeform.com/to/B7xSMbos#${query}`}
      ></iframe>
      <script
        type="text/javascript"
        src="https://embed.typeform.com/embed.js"
      ></script>
    </>
  );
};

export default PageParticipate;
