import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useQuery } from "../../helpers";
import { useStore } from "../../store";
import Legend from "../Legend";
import Filter from "../Filter";
import Graph from "../Graph";

const PageGraph = () => {
  const setFilters = useStore((state) => state.setFiltersFromSearchQuery);
  const setReferrer = useStore((state) => state.setReferrer);
  const location = useLocation();
  const query = useQuery();

  useEffect(() => {
    setReferrer();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    setFilters(query);
  }, [location.search]);

  return (
    <section>
      <Filter />
      <Graph />
      {/* <Legend /> */}
    </section>
  );
};

export default PageGraph;
