import React, { useEffect } from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import * as firebase from "firebase/app";
import "firebase/database";
import { useStore } from "../store";
import { firebaseConfig } from "../config";
import {
  PageView,
  PageGraph,
  PageParticipate,
  PageRate,
  PageHome,
  PageImprint,
  Page404,
} from "./Pages";
import Header from "./Header";

import "./style.scss";

const isTouchDeviceSelector = (state) => state.isTouchDevice;
const App = () => {
  const [initialized, initialize] = useStore((state) => [
    state.initialized,
    state.initialize,
  ]);
  const isTouchDevice = useStore(isTouchDeviceSelector);
  const decorator = isTouchDevice ? "touchDevice" : "desktop";

  useEffect(() => {
    if (initialized) return;
    try {
      const app = firebase.initializeApp(firebaseConfig);
      const database = app.database();
      database
        .ref("/1Xr4qgZllkOjjE36ArmEml0rmwFM2r-ALdvTV8VdXako/Ratings")
        .once("value")
        .then((snapshot) => initialize(snapshot.val()));
    } catch (error) {}
    return () => console.log("THIS IS THE END!");
  }, []);

  return (
    <HashRouter>
      <>
        <div id="App" className={decorator}>
          <div className="inner">
            <Header />
            {initialized && (
              <Routes>
                <Route path={`/`} element={<PageHome />} />
                <Route path={`/explore`} element={<PageGraph />} />
                <Route path={`/view/:ids`} element={<PageView />} />
                <Route path={`/imprint`} element={<PageImprint />} />
                {/* <Route path={`/not-found`} element={<Page404 />} /> */}
              </Routes>
            )}
          </div>
        </div>
        <Routes>
          <Route path={`/submit`} element={<PageParticipate />} />
          <Route path={`/rate/:token/:text`} element={<PageRate />} />
        </Routes>
      </>
    </HashRouter>
  );
};

export default App;
