import React from "react";

const Page404 = () => {
  return <h2>the entries your are looking for where not found</h2>;
};

const PageParticipate = () => {
  return (
    <>
      <iframe
        id="Typeform-submission"
        frameBorder="0"
        allow="camera; microphone; autoplay; encrypted-media;"
        src="https://form.typeform.com/to/nMBGCE23"
      ></iframe>
      <script
        type="text/javascript"
        src="https://embed.typeform.com/embed.js"
      ></script>
    </>
  );
};

export default PageParticipate;
