import create from "zustand";
import _ from "lodash";
import gini from "gini";
import Fuse from "fuse.js";
import isTouchDevice from "is-touch-device";
import { strHash } from "./helpers";

const filterGroups = (data) => {
  const countries = Array.from(
    Object.values(data).reduce((acc, entry) => {
      entry.forEach((element) => {
        if (element?.active !== false)
          element?.ratings?.forEach((rating) => acc.add(rating?.country));
      });
      return acc;
    }, new Set([]))
  ).sort();

  const categories = Array.from(
    Object.values(data).reduce((acc, entry) => {
      entry.forEach((element) => {
        if (element?.active !== false)
          element?.categories?.ml?.forEach((category) => acc.add(category));
      });
      return acc;
    }, new Set([]))
  ).sort();

  const tags = Array.from(
    Object.values(data).reduce((acc, entry) => {
      entry.forEach((element) => {
        if (element?.active !== false)
          element?.categories?.tags?.forEach((tag) => acc.add(tag));
      });
      return acc;
    }, new Set([]))
  ).sort();

  const filters = {
    countries,
    categories,
    tags,
  };

  return filters;
};

const filterData = (data, filters) => {
  const { search, country, category, tag1, tag2, tag3 } = filters;
  let fixedDivider = 9;
  if (screen.width <= 560) {
    fixedDivider = 2;
  } else if (isTouchDevice()) {
    fixedDivider = 6;
  }
  let stack = [];
  Object.entries(data).forEach(([key, point]) => {
    const entries = point.forEach((entry) => {
      const ratings = entry?.ratings?.map((rating) => ({
        ...rating,
        active: country ? rating?.country === country : true,
      }));

      const activeByCountry = country
        ? ratings?.filter((rating) => rating.active).length > 0
        : true;

      const activeByCategory = category
        ? _.includes(entry?.categories?.ml, category)
        : true;

      const activeByTag1 = tag1
        ? _.includes(entry?.categories?.tags, tag1)
        : true;

      const activeByTag2 = tag2
        ? _.includes(entry?.categories?.tags, tag2)
        : true;

      const activeByTag3 = tag3
        ? _.includes(entry?.categories?.tags, tag3)
        : true;

      const activeByTags = activeByTag1 && activeByTag2 && activeByTag3;

      const active = activeByCountry && activeByCategory && activeByTags;
      const ratingsFromCountry = entry.ratings.filter(
        (o) => !country || o.country === country
      );
      const meanX = Number(_.meanBy(ratingsFromCountry, "probable")) || -1;
      const meanY = Number(_.meanBy(ratingsFromCountry, "desirable")) || -1;
      const position = {
        x: Math.round(meanX * fixedDivider) / fixedDivider,
        y: Math.round(meanY * fixedDivider) / fixedDivider,
      };

      stack.push({
        ...entry,
        ...position,
        ratings,
        active,
      });
    });
  });

  if (search) {
    const fuse = new Fuse(stack, {
      includeScore: true,
      minMatchCharLength: 3,
      ignoreLocation: true,
      threshold: 0.4,
      keys: ["text", "categories.ml", "categories.tags"], //"countries",
    });
    stack.forEach((entry, index) => {
      stack[index].active = false;
    });
    const result = fuse.search(search);
    result
      .filter((entry) => entry.score <= 0.4)
      .forEach((entry) => {
        stack[entry.refIndex].active = true;
      });
  }

  const grouped = _.groupBy(stack, (o) => [o.x, o.y]);
  return grouped;
};

const parseCollection = (collection) => {
  let fixedDivider = 9;
  if (screen.width <= 560) {
    fixedDivider = 2;
  } else if (isTouchDevice()) {
    fixedDivider = 6;
  }
  const filtered = Object.values(collection)
    .filter(
      (entry) =>
        entry?.["Token"] !== "" &&
        entry?.["Desirability"] !== "" &&
        entry?.["Probability"] !== "" &&
        entry?.["Desirability"] > 0 &&
        entry?.["Probability"] > 0 &&
        entry?.["Desirability"] <= 10 &&
        entry?.["Probability"] <= 10 &&
        entry?.["Submitted At"] !== "" &&
        entry?.["question"] !== "" &&
        entry?.["Country"] !== undefined &&
        entry?.["category"] !== undefined &&
        entry?.["tag1"] !== undefined &&
        entry?.["tag2"] !== undefined &&
        entry?.["tag3"] !== undefined
    )
    .map((entry) => ({
      id: entry.Token,
      token: strHash(entry.question),
      country: entry.Country || "unknown",
      probable: entry.Probability,
      desirable: entry.Desirability,
      text: entry.question,
      category: entry.category,
      tag1: entry.tag1,
      tag2: entry.tag2,
      tag3: entry.tag3,
      date: entry["Submitted At"],
    }));
  const groupedByToken = _.groupBy(filtered, "token");
  const normalized = Object.values(groupedByToken).map((group) =>
    group.reduce(
      (acc, entry) => {
        acc.active = true;
        acc.id = entry.id;
        acc.token = entry.token;
        acc.text = entry.text;
        acc.date = entry.date.substr(0, 10);
        if (entry.category) acc.categories.ml.push(entry.category);
        if (entry.tag1) acc.categories.tags.push(entry.tag1);
        if (entry.tag2) acc.categories.tags.push(entry.tag2);
        if (entry.tag3) acc.categories.tags.push(entry.tag3);
        acc.ratings.push({
          country: entry.country,
          desirable: parseFloat(entry.desirable),
          probable: parseFloat(entry.probable),
        });
        acc.countries = acc.ratings.map((entry) => entry.country).join(",");
        const meanX = _.meanBy(acc.ratings, "probable") || -1;
        const meanY = _.meanBy(acc.ratings, "desirable") || -1;
        acc.x = Math.round(meanX * fixedDivider) / fixedDivider;
        acc.y = Math.round(meanY * fixedDivider) / fixedDivider;
        return acc;
      },
      {
        active: true,
        id: "",
        token: "",
        text: "",
        date: "",
        countries: "",
        categories: { ml: [], tags: [] },
        ratings: [],
        controverse: "",
        diverse: "",
        x: 0,
        y: 0,
      }
    )
  );

  const calculateDiversity = (ratings = []) => {
    if (ratings.length < 2) return "";
    const d = _.map(ratings, "desirable");
    const desirable = gini.unordered(d);

    const p = _.map(ratings, "probable");
    const probable = gini.unordered(p);

    return (desirable + probable) / 2;
  };
  const calculateControversity = (ratings = []) => {
    if (ratings.length < 2) return "";
    const maxD = _.maxBy(ratings, "desirable").desirable;
    const minD = _.minBy(ratings, "desirable").desirable;
    const desirable = (maxD - minD + 1) / (maxD || 1);

    const maxP = _.maxBy(ratings, "probable").probable;
    const minP = _.minBy(ratings, "probable").probable;
    const probable = (maxP - minP + 1) / (maxP || 1);

    return (desirable + probable) / 2;
  };
  const computed = normalized.map((entry) => {
    entry?.controverse > 0.75;
    const controverse = calculateControversity(entry.ratings);
    const diverse = calculateDiversity(entry.ratings);
    const ml = controverse > 0.75 ? ["most controversial"] : [];
    return {
      ...entry,
      controverse,
      diverse,
      categories: {
        tags: entry.categories.tags,
        ml: [...ml, ...entry.categories.ml],
      },
    };
  });
  const groupedByPosition = _.groupBy(computed, (o) => [o.x, o.y]);
  return groupedByPosition;
};

export const useStore = create((set, get) => ({
  tooltip: null,
  allowTooltip: true,
  isTouchDevice: isTouchDevice(),
  initialized: false,
  data: {},
  filters: {
    search: null,
    country: null,
    category: null,
    tag1: null,
    tag2: null,
    tag3: null,
  },
  brushBounds: null,
  referrer: false,
  setReferrer: () => {
    set(() => ({
      referrer: true,
    }));
  },
  initialize: (collection) => {
    const currentData = parseCollection(collection);
    set(() => ({
      data: { ...currentData },
      initialized: true,
    }));
  },
  getFilterGroups: () => {
    const data = get().data;
    return filterGroups(data);
  },
  setFiltersFromSearchQuery: (query) => {
    const filters = {
      search: query.get("search"),
      country: query.get("country"),
      category: query.get("category"),
      tag1: query.get("tag1"),
      tag2: query.get("tag2"),
      tag3: query.get("tag3"),
    };
    const data = get().data;
    const currentData = filterData(data, filters);
    set(() => ({
      data: { ...currentData },
      filters: filters,
    }));
  },
  setFilter: ({ filter, value }) => {
    const data = get().data;
    const filters = get().filters;
    const currentFilters = {
      ...filters,
      [filter]: value,
    };
    const currentData = filterData(data, currentFilters);
    set(() => ({
      data: { ...currentData },
      filters: currentFilters,
    }));
  },
  onBrushChange: (bounds) => {
    set((state) => ({ brushBounds: bounds }));
  },
  openTooltip: (data) => {
    if (allowTooltip) set((state) => ({ tooltip: data }));
  },
  closeTooltip: () => {
    set((state) => ({ tooltip: null }));
  },
  allowTooltip: (active) => {
    set({ allowTooltip: active });
  },
}));
