import React, { useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

export function randomDate(start, end) {
  end = end ? end : new Date();
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

export function formatDate(date) {
  let d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

export const useQuery = () => {
  const { search } = useLocation();
  return new URLSearchParams(search);
};

function _bitwise(str) {
  let hash = 0;
  if (str.length == 0) return hash;
  for (let i = 0; i < str.length; i++) {
    let ch = str.charCodeAt(i);
    hash = (hash << 5) - hash + ch;
    hash = hash & hash; // Convert to 32bit integer
  }
  return hash;
}

function _binaryTransfer(integer, binary) {
  binary = binary || 62;
  let stack = [];
  let num;
  let result = "";
  let sign = integer < 0 ? "-" : "";
  function table(num) {
    let t = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    return t[num];
  }
  integer = Math.abs(integer);
  while (integer >= binary) {
    num = integer % binary;
    integer = Math.floor(integer / binary);
    stack.push(table(num));
  }
  if (integer > 0) {
    stack.push(table(integer));
  }
  for (let i = stack.length - 1; i >= 0; i--) {
    result += stack[i];
  }
  return sign + result;
}

export const strHash = (text) => {
  let id = _binaryTransfer(_bitwise(text), 61);
  return id.replace("-", "Z");
};

export const useLongPress = (
  onLongPress,
  onClick,
  { shouldPreventDefault = true, delay = 300 } = {}
) => {
  const [longPressTriggered, setLongPressTriggered] = useState(false);
  const timeout = useRef();
  const target = useRef();

  const start = useCallback(
    (event) => {
      event.persist();
      if (shouldPreventDefault && event.target) {
        event.target.addEventListener("touchend", preventDefault, {
          passive: false,
        });
        target.current = event.target;
      }
      timeout.current = setTimeout(() => {
        onLongPress(event);
        setLongPressTriggered(true);
      }, delay);
    },
    [onLongPress, delay, shouldPreventDefault]
  );

  const clear = useCallback(
    (event, shouldTriggerClick = true) => {
      timeout.current && clearTimeout(timeout.current);
      shouldTriggerClick && !longPressTriggered && onClick();
      setLongPressTriggered(false);
      if (shouldPreventDefault && target.current) {
        target.current.removeEventListener("touchend", preventDefault);
      }
    },
    [shouldPreventDefault, onClick, longPressTriggered]
  );

  return {
    onMouseDown: (e) => start(e),
    onTouchStart: (e) => start(e),
    onMouseUp: (e) => clear(e),
    onMouseLeave: (e) => clear(e, false),
    onTouchEnd: (e) => clear(e),
  };
};

const isTouchEvent = (event) => {
  return "touches" in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

function preventDefault2(e) {
  e.preventDefault();
}

export const disableScroll = () => {
  document.body.style.position = "fixed";
  document.body.addEventListener("touchmove", preventDefault2, {
    passive: false,
  });
};
export const enableScroll = () => {
  document.body.style.position = "relative";
  document.body.removeEventListener("touchmove", preventDefault2);
};
