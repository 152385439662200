import React from "react";
import { useTooltip } from "@visx/tooltip";
import { useStore } from "../../store";
import Tooltip from "./Tooltip";
import Canvas from "./Canvas";
import Legend from "../Legend";

import "./style.scss";

const onBrushChangeSelector = (state) => state.onBrushChange;
const Graph = () => {
  const onBrushChange = useStore(onBrushChangeSelector);
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();

  return (
    <div id="Graph">
      <div className="inner">
        <Canvas
          key="canvas"
          onOpen={showTooltip}
          onClose={hideTooltip}
          onBrushChange={onBrushChange}
        />
        <Legend />
        <Tooltip
          key="tooltip"
          data={tooltipData}
          open={tooltipOpen}
          position={[tooltipLeft, tooltipTop]}
        />
      </div>
    </div>
  );
};

export default Graph;
