import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import View from "../View";

const PageView = () => {
  const { ids } = useParams();
  const tokens = ids.split(",");
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);
  return (
    <section>
      <View tokens={tokens} />
    </section>
  );
};

export default PageView;
