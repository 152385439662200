import React from "react";
import { Link } from "react-router-dom";

import "./style.scss";

const PageHome = () => {
  return (
    <div id="Home">
      <div className="inner">
        <h3>How might our world have changed by 2030?</h3>

        <div>
          <p>
            Out of every present moment, endless possibilities for our future
            emerge. What might mean the world for us tomorrow, may only seem
            like a niche oddity today. Things that make the world go round
            today, might hardly be remembered in some years time.
          </p>
          <p>
            Cartographies of Change is an initiative to build a crowd-sourced,
            open access map of our future to collectively explore the
            opportunities and challenges that lie ahead on our path towards a
            more just and sustainable world by 2030. All displayed statements
            have been submitted to the platform by anonymous users around the
            world. Individual statements are rated collectively regarding their
            probability and desirability.
          </p>
          <p>
            If you want to contribute to the map,{" "}
            <i>
              <Link to="/submit">please submit your future thought</Link>
            </i>{" "}
            and{" "}
            <i>
              <a href="https://cutt.ly/ld7UexX">
                evaluate the thoughts of others
              </a>
            </i>
            . You can also{" "}
            <i>
              <Link to="/explore">browse the submissions</Link>
            </i>{" "}
            we collected so far.
          </p>
          <h4>Who is behind this?</h4>
          <p>
            Cartographies of Change is developed by{" "}
            <a href="https://www.ride-futures.com/" target="_blank">
              RIDE Futures
            </a>{" "}
            as part of{" "}
            <a href="https://www.interweavingchange.org/" target="_blank">
              Interweaving Change
            </a>
            , a collaboration of the{" "}
            <a href="https://bmw-foundation.org/" target="_blank">
              BMW Foundation Herbert Quandt
            </a>{" "}
            and{" "}
            <a href="https://state-studio.com/" target="_blank">
              STATE Studio
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default PageHome;
