import PageView from "./PageView";
import PageGraph from "./PageGraph";
import PageParticipate from "./PageParticipate";
import PageRate from "./PageRate";
import PageHome from "./PageHome";
import PageImprint from "./PageImprint";
import Page404 from "./Page404";

export {
  PageView,
  PageGraph,
  PageParticipate,
  PageRate,
  PageHome,
  PageImprint,
  Page404,
};
