import React, { useState, memo } from "react";
import _ from "lodash";
import { Grid } from "@visx/grid";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { AxisLeft, AxisBottom } from "@visx/axis";
import { scaleLinear } from "@visx/scale";

const Canvas = ({ square, points = [] }) => {
  const scalePoints = scaleLinear({
    domain: [1, 10],
    range: [6, square - 6],
    round: true,
  });
  const scaleGrid = scaleLinear({
    domain: [0, 1],
    range: [0, square],
    round: true,
  });

  const circles = _.groupBy(points, (o) => [o.probable, o.desirable]);
  return (
    <svg width={square} height={square}>
      <Grid
        xScale={scaleGrid}
        yScale={scaleGrid}
        width={square}
        height={square}
        strokeOpacity={0.5}
      />
      <Group>
        <AxisBottom
          className="axis"
          top={square / 2}
          scale={scaleGrid}
          numTicks={0}
        />
        <AxisLeft
          className="axis"
          left={square / 2}
          scale={scaleGrid}
          numTicks={0}
        />
        <Text dx={square / 2 + 3} dy={0} angle={90}>
          desirable
        </Text>
        <Text dx={square - 38} dy={square / 2 + 8}>
          probable
        </Text>
      </Group>

      <Group className="data-points">
        {Object.values(circles).map((entry, index) => {
          const [point] = entry;
          const radius = 2 + entry.length;
          return (
            <circle
              key={`point-${index}`}
              cx={scalePoints(point.probable)}
              cy={scalePoints(11 - point.desirable)}
              r={radius}
              fill="black"
            />
          );
        })}
      </Group>
    </svg>
  );
};

export default memo(Canvas);
