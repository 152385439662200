import React from "react";
import { NavLink, useNavigate } from "react-router-dom";

import "./style.scss";

const Pointer = ({ number }) => (
  <span>
    <b>{number} </b>
    <span aria-hidden="true" className="icon__Boundary-sc-2r3yc0-0 kvvuXL">
      <svg height="10" width="11">
        <path d="M7.586 5L4.293 1.707 5.707.293 10.414 5 5.707 9.707 4.293 8.293z"></path>
        <path d="M8 4v2H0V4z"></path>
      </svg>
    </span>{" "}
  </span>
);

const Legend = () => {
  const navigate = useNavigate();
  const activeStyle = {
    textDecoration: "underline",
  };
  return (
    <div id="Legend">
      <div className="inner">
        <div className="intro">
          <h4>How to use the map</h4>
          <div>
            <Pointer number={1} /> Hover over a point to see its content.{" "}
            <Pointer number={2} />
            Click on a point for details. <Pointer number={3} />
            Draw a rectangle over several points to see their details.{" "}
            <Pointer number={4} />
            Choose one or more tags to limit the view to submissions that match
            all of these criteria. <Pointer number={5} />
            Use the search bar to look for specific content.{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Legend;
