import React from "react";
import { Group } from "@visx/group";
import { AxisLeft, AxisBottom } from "@visx/axis";

const AxisCenter = ({ size, scale }) => {
  return (
    <Group>
      <AxisBottom className="axis" top={size / 2} scale={scale} numTicks={0} />
      <AxisLeft className="axis" left={size / 2} scale={scale} numTicks={0} />
    </Group>
  );
};

export default AxisCenter;
