import React, { memo } from "react";
import { Group } from "@visx/group";
import { useStore } from "../../store";
import Circle from "./Circle";

const brushBoundsSelector = (state) => state.brushBounds;
const isTouchDeviceSelector = (state) => state.isTouchDevice;
const Circles = memo(({ data, scale, onOpen, onClose }) => {
  const brushBounds = useStore(brushBoundsSelector);
  const isTouchDevice = useStore(isTouchDeviceSelector);
  const circles = Object.entries(data).map(([index, points]) => {
    let coords = index.split(",").map(parseFloat);
    coords[1] = 11 - coords[1];
    const highlighted = brushBounds
      ? coords[0] >= brushBounds.x0 &&
        coords[0] <= brushBounds.x1 &&
        coords[1] >= brushBounds.y0 &&
        coords[1] <= brushBounds.y1
      : false;
    return (
      <Circle
        key={index}
        index={index}
        points={points}
        scale={scale}
        onOpen={onOpen}
        onClose={onClose}
        highlighted={highlighted}
        isTouchDevice={isTouchDevice}
      />
    );
  });

  return <Group id="data-points">{circles}</Group>;
});

export default Circles;
