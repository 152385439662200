export const basePath = "";

export const firebaseConfig = {
  apiKey: "AIzaSyBXeMMVc-cRSnuMEpBIf7QYUbml0OqYpD4",
  authDomain: "ride-286315.firebaseapp.com",
  databaseURL: "https://ride-286315.firebaseio.com",
  projectId: "ride-286315",
  storageBucket: "ride-286315.appspot.com",
  messagingSenderId: "297480391962",
  appId: "1:297480391962:web:ec154dfab6ce14d7c830a3",
};
