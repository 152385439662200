import React from "react";
import { NavLink, useLocation } from "react-router-dom";

import "./style.scss";

const Header = () => {
  const loaction = useLocation();
  const isRate = loaction.pathname.substr(0, 5) === "/rate";
  const isView = loaction.pathname.substr(0, 5) === "/view";
  const activeStyle = {
    textDecoration: "underline",
  };
  return (
    <div id="Header">
      <div className="inner">
        <div className="logo">Cartographies of Change</div>
        <nav>
          <NavLink to="/" activeStyle={activeStyle} end>
            Home
          </NavLink>{" "}
          |{" "}
          <a
            href="https://cr368540.typeform.com/to/B7xSMbos?question=Humanity%20will%20start%20all%20over%20on%20Mars"
            style={
              isRate
                ? {
                    textDecoration: "underline",
                  }
                : null
            }
            target="_blank"
          >
            Rate
          </a>{" "}
          |{" "}
          <NavLink to="/submit" activeStyle={activeStyle}>
            Submit
          </NavLink>{" "}
          |{" "}
          <NavLink
            to="/explore"
            activeStyle={activeStyle}
            style={
              isView
                ? {
                    textDecoration: "underline",
                  }
                : null
            }
          >
            Explore
          </NavLink>{" "}
          |{" "}
          <NavLink to="/imprint" activeStyle={activeStyle}>
            Imprint
          </NavLink>
        </nav>

        {/* <div className="intro">
          <>
            Out of every present moment, endless possibilities for our future
            emerge. What will mean the world for us tomorrow, might only seem
            like a niche oddity today. What makes the world go round today,
            might hardly be remembered in some years time.
          </>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
